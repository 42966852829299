<template>
  <div
    v-tooltip="$t('COMMON.UPDATE_RECORD')"
    class="relative flex items-center justify-end cursor-pointer"
    @click="showModal = true"
  >
    <span
      class="flex items-center justify-center hover:bg-gray-100 w-10 h-10 p-2 rounded"
    >
      <icon-base
        data-cy="update-record"
        class="w-4 h-4 text-disabled-medium"
        icon="edit"
      />
    </span>
    <update-record
      v-if="showModal"
      :class="placementClass"
      :has-subtitle="false"
      :title="$t('DEALS.PULL_DATA_FROM_SERVICE', { service: serviceName })"
      :field-title="model.field"
      :model="{
        oldValue: model.modelValues.oldValue,
        newValue: model.modelValues.newValue
      }"
      :on-confirm="
        () => {
          $emit('record:update', model);
          showModal = false;
        }
      "
      :on-dismiss="() => (showModal = false)"
    />
  </div>
</template>

<script setup lang="ts">
import UpdateRecord from "@/components/deals/dataServices/UpdateRecord.vue";
import type { ServiceUpdateRecord } from "@/models/common";
import { ref } from "vue";

defineEmits<{
  "record:update": [ServiceUpdateRecord];
}>();

withDefaults(
  defineProps<{
    model: ServiceUpdateRecord;
    serviceName: string;
    placementClass?: string;
  }>(),
  {
    placementClass: "left-0 top-0"
  }
);

const showModal = ref(false);
</script>
